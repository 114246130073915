export class Keys {
    public static readonly school = 'school';
    public static readonly campus = 'campus';
    public static readonly contact = 'contact';
    public static readonly student = 'student';
    public static readonly students = 'students';
    public static readonly schoolLogo = 'schoolLogo';
    public static readonly emailSignature = 'emailSignature';
    public static readonly years = 'year';
    public static readonly ptYear = 'year';
    public static readonly appointmentType = 'appointmentType';

    public static readonly enquiryDateRange = 'enquiryDateRange';

    public static readonly contactType = 'contactType';
    public static readonly contactTypeId = 'contactTypeId';
    public static readonly contactLastName = 'contact.lastName';
    public static readonly contactAddress = 'contact.address';
    public static readonly contactCity = 'city';
    public static readonly contactCountry = 'Country';
    public static readonly contactCountryId = 'countryId';
    public static readonly contactState = 'State';
    public static readonly contactMobile = 'contact.mobile';
    public static readonly contactHomePhone = 'contact.homePhone';
    public static readonly contactWorkPhone = 'contact.workPhone';
    public static readonly contactAlumniId = 'contact.alumniId';

    public static readonly studentLastName = 'student.lastName';
    public static readonly studentStartingYear = 'student.startingYear';
    public static readonly studentSchoolIntakeYearId = 'student.schoolIntakeYearId';
    public static readonly studentStageId = 'student.studentStatus.stageId';
    public static readonly studentStudentStatusId = 'student.studentStatusId';
    public static readonly studentGenderId = 'student.genderId';
    public static readonly studentFinancialAidId = 'student.financialAidId';
    public static readonly studentReligionId = 'student.religionId';
    public static readonly studentSiblingsId = 'student.siblingsId';
    public static readonly studentIsExported = 'student.isExported';
    public static readonly studentTransfer = 'student.studentTransfer.transferStatus';
    public static readonly studentOtherInterests = 'student.otherInterests';
    public static readonly studentHasSpecialNeeds = 'student.hasSpecialNeeds';
    public static readonly studentMarkRecord = 'student.markRecord';
    public static readonly relationshipTypeId = 'relationshipTypeId';
    public static readonly yearMonthOfBirth = 'yearMonthOfBirth';
    public static readonly birthMonth = 'birthMonth';
    public static readonly birthDate = 'birthDate';
    public static readonly studentCurrentSchool = 'student.currentSchool.schoolName';
    public static readonly studentCurrentSchoolId = 'student.currentSchoolId';
    public static readonly studentCurrentSchoolStatusId = 'student.currentSchool.statusId';
    public static readonly studentCurrentSchoolTypeId = 'student.currentSchool.schoolTypeId';
    public static readonly studentLeadSourceId = 'student.leadSourceId';
    public static readonly studentHearAboutUsId = 'student.hearAboutUsId';
    public static readonly studentBoardingTypeId = 'student.boardingTypeId';
    public static readonly studentIsInternational = 'student.isInternational';
    public static readonly studentActivityId = 'activityId';

    public static readonly lastName = 'lastName';
    public static readonly dateOfBirth = 'dateOfBirth';
    public static readonly startingYear = 'startingYear';
    public static readonly gender = 'gender';
    public static readonly genderId = 'genderId';
    public static readonly religion = 'religion';
    public static readonly religionId = 'religionId';
    public static readonly siblings = 'siblings';
    public static readonly futureSiblings = 'futureSiblings';
    public static readonly familyConnection = 'familyConnection';
    public static readonly studentInterests = 'studentInterests';
    public static readonly siblingsId = 'siblingsId';
    public static readonly alumni = 'alumni';
    public static readonly isExported = 'isExported';
    public static readonly transferStatus = 'transferStatus';
    public static readonly markRecord = 'markRecord';
    public static readonly schoolIntakeYear = 'schoolIntakeYear';
    public static readonly schoolIntakeYearId = 'schoolIntakeYearId';
    public static readonly leadSource = 'leadSource';
    public static readonly leadSourceId = 'leadSourceId';
    public static readonly hearAboutUs = 'hearAboutUs';
    public static readonly declineByReason = 'declineByReason';
    public static readonly hearAboutUsId = 'hearAboutUsId';
    public static readonly studentStatus = 'studentStatus';
    public static readonly studentStatusId = 'studentStatusId';
    public static readonly financialAidId = 'financialAidId';
    public static readonly otherInterests = 'otherInterests';
    public static readonly otherInterestId = 'otherInterestId';
    public static readonly familyConnectionId = 'familyConnectionId';
    public static readonly hasFutureSiblings = 'hasFutureSiblings';
    public static readonly additionalNeedsId = 'additionalNeedsId';
    public static readonly hasSpecialNeeds = 'hasSpecialNeeds';
    public static readonly specialNeedsReason = 'specialNeedsReason';
    public static readonly boardingType = 'boardingType';
    public static readonly boardingTypeId = 'boardingTypeId';
    public static readonly isInternational = 'isInternational';
    public static readonly countryOfOrigin = 'countryOfOrigin';
    public static readonly countryOfOriginId = 'countryOfOriginId';
    public static readonly currentSchool = 'currentSchool';
    public static readonly currentSchoolId = 'currentSchoolId';
    public static readonly currentSchoolYear = 'currentSchoolYear';
    public static readonly currentSchoolYearId = 'currentSchoolYearId';
    public static readonly currentSchoolName = 'currentSchool.name';
    public static readonly currentSchoolStatus = 'currentSchool.status';
    public static readonly currentSchoolType = 'currentSchool.schoolType';
    public static readonly stageId = 'stageId';
    public static readonly studentStatusStageId = 'studentStatus.stageId';
    public static readonly submittedApplication = 'submittedApplication';
    public static readonly yearlyComparisonRange = 'yearlyComparisonRange';
    public static readonly compareStartingYear = 'compareStartingYear';
    public static readonly additionalNeedsIds = 'additionalNeedsIds';
    public static readonly reasonForSelection = 'reasonForSelection';
    public static readonly declineByLastStatus = 'declineByLastStatus';

    public static readonly applicationStatus = 'applicationStatus';
    public static readonly formTemplateId = 'formTemplateId';
    public static readonly campusId = 'campusId';

    public static readonly event = 'event';
    public static readonly displayedEvents = 'displayedEvents';
    public static readonly personalTour = 'personalTour';
    public static readonly eventTypeList = 'eventTypeList';

    public static readonly isAttendedEvent = 'isAttendedEvent';
    public static readonly isRegisteredEvent = 'isRegisteredEvent';

    public static readonly isAttendedPersonalTour = 'isAttendedPersonalTour';
    public static readonly isRegisteredPersonalTour = 'isRegisteredPersonalTour';

    // keys for backend filtering
    public static readonly bfStudentLastName = 'studentLastName';

    public static readonly bfContactLastName = 'contactLastName';
    public static readonly bfContactAddress = 'contactAddress';
    public static readonly bfContactCity = 'contactCity';
    public static readonly bfContactMobile = 'contactMobile';
    public static readonly bfContactHomePhone = 'contactHomePhone';
    public static readonly bfContactWorkPhone = 'contactWorkPhone';
    public static readonly bfContactCountryId = 'contactCountryId';
    public static readonly bfContactAlumniId = 'contactAlumniId';
    public static readonly bfContactStateId = 'contactStateId';
    public static readonly bfContactTypeId = 'contactTypeId';
    public static readonly bfReceiveUpdatesByEmail = 'receiveUpdatesByEmail';
    public static readonly bfReceiveUpdatesBySms = 'receiveUpdatesBySms';

    public static readonly bfCurrentSchoolId = 'currentSchoolId';
    public static readonly bfCurrentSchoolStatusId = 'currentSchoolStatusId';
    public static readonly bfCurrentSchoolTypeId = 'currentSchoolTypeId';

    // Keys for Chart store
    public static readonly funnel = 'funnel';
    public static readonly enquiryByMonth = 'enquiryByMonth';
    public static readonly enquiriesYearlyComparison = 'enquiriesYearlyComparison';
    public static readonly funnelMetricsByStartingYear = 'funnelMetricsByStartingYear';
    public static readonly appsByStartingYear = 'appsByStartingYear';
    public static readonly yearlyComparisonOfFormsReceived = 'yearlyComparisonOfFormsReceived';
}
