import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { Constants } from 'app/common/constants';
import { ModalAction } from 'app/common/enums';
import Swal, { SweetAlertResult } from 'sweetalert2';

import { SanitizeMobileModalService } from './sanitize-mobile-modal.service';

@Component({
    selector: 'app-sanitize-mobile-modal',
    templateUrl: './sanitize-mobile-modal.component.html',
    providers: [SanitizeMobileModalService],
    imports: [CommonModule, MatExpansionModule, Angular2PromiseButtonModule],
    standalone: true,
})
export class SanitizeMobileModalComponent implements OnInit {
    promiseForBtn: Promise<{ successCount: number; errorCount: number }>;
    countContacts = 0;

    constructor(private activeModal: NgbActiveModal, private sanitizeMobileModalService: SanitizeMobileModalService) {}
    async ngOnInit() {
        this.countContacts = await this.sanitizeMobileModalService.countContacts();
    }

    async sanitizeDataSubmit() {
        this.promiseForBtn = this.sanitizeMobileModalService.sanitizeMobileNumbers();
        const result = await this.promiseForBtn;
        this.activeModal.close({ action: ModalAction.Select });
        await this.infoAlert(result);
    }

    onCancel() {
        this.activeModal.close({ action: ModalAction.Cancel });
    }

    public infoAlert(result: { successCount: number; errorCount: number }): Promise<SweetAlertResult> {
        return Swal.fire({
            title: 'Data Successfully Sanitized',
            html: `<br>\
            Successfully updated ${result.successCount} mobile numbers\
            <br>\
            ${result.errorCount} mobile numbers could not be updated`,
            ...Constants.successOnly,
            buttonsStyling: false,
            allowOutsideClick: false,
        });
    }
}
