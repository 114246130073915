<div class="modal-header">
    <h4 class="modal-title" id="whatsNewLabel">New Phone Number Format!</h4>
</div>
<div class="modal-body">
    <img src="assets/img/sanitize.png" width="100%" alt="Sanitize Mobile Modal Image" />
    <p>
        We are adopting <strong>E.164</strong>, an internationally recognized standard phone number format, which comes with a new phone
        number input component with a country selector, available in all related entry points across the product, including all our forms.
    </p>
    <p>This will help:</p>
    <ul>
        <li>Maintain data hygiene & consistency</li>
        <li>Ensure compatibility when synchronizing with other platforms</li>
        <li>Simplify transition if you purchase SMS Communications module</li>
    </ul>

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title> What happens if I don't convert now? </mat-panel-title>
            </mat-expansion-panel-header>
            When accessing a <strong>Contact Details</strong> page with an invalid phone number, you'll be asked to automatically convert or
            update it manually before proceeding.
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title> What happens to phone numbers that fail to be converted? </mat-panel-title>
            </mat-expansion-panel-header>
            If certain phone numbers fail to be converted, they will be preserved so next time you access a
            <strong>Contact Details</strong> page with such a number, you can either update it manually or remove it before proceeding.
        </mat-expansion-panel>
    </mat-accordion>
    <br />
    <p>
        Click <strong>Standardize My Numbers</strong> to process all {{ countContacts }} contact phone numbers in your system. We will only
        replace those numbers that can be safely converted into the new format. Click here to learn more about the logic behind the
        conversion process.
    </p>
</div>

<div class="modal-footer">
    <div class="mx-auto">
        <button type="button" mat-raised-button class="btn btn-danger mx-1" (click)="onCancel()">NOT NOW</button>
        <button [promiseBtn]="promiseForBtn" type="button" mat-raised-button class="btn btn-success" (click)="sanitizeDataSubmit()">
            Standardize My Numbers
        </button>
    </div>
</div>
